import React from 'react';
import { motion } from 'framer-motion';
import { Collapse } from 'react-bootstrap';
import Img from 'gatsby-image';
import ReactHtmlParser from 'react-html-parser';

function faqSection({
  sectionTitle,
  sectionPlaceholder,
  faqsList,
  searchButton,
}) {
  const [selected, setSelected] = React.useState(null);
  const [value, setValue] = React.useState('');
  return (
    <div className="faq-section">
      <div className="searchbar-warpper">
        <h2>{ReactHtmlParser(sectionTitle)}</h2>
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={sectionPlaceholder}
        />
        <input className="pretty-button" type="button" value={searchButton} />
      </div>
      <div className="faqs-list">
        {faqsList.map((item, index) => {
          if (
            item.title.toLowerCase().includes(value.toLowerCase())
            || item.description.toLowerCase().includes(value.toLowerCase())
          ) {
            return (
              <motion.div
                key={item.title}
                initial={{ x: 200, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 200, opacity: 0 }}
                className="question-warpper"
              >
                <h3 className="question">{ReactHtmlParser(item.title)}</h3>
                <Collapse in={selected === index}>
                  <div
                    className={`answer ${selected === index ? 'show' : ''} `}
                  >
                    <p>{ReactHtmlParser(item.description)}</p>
                    {item.image && (
                      <Img fluid={item.image.localFile.childImageSharp.fluid} />
                    )}
                  </div>
                </Collapse>
                <input
                  className="pretty-button"
                  type="button"
                  value={
                    index === selected
                      ? item.button_label_close
                      : item.button_label
                  }
                  onClick={() => setSelected(selected === index ? null : index)}
                />
              </motion.div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default faqSection;
