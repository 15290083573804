import React from "react";
import { graphql } from "gatsby";
import CoverImageTitle from "../components/coverImageTitle";
import FaqSerction from "../components/faqSection";
import CoverImage from "../components/coverImage";
import ContactForm from "../components/ContactForm";

const FAQTemplate = props => {
  const {
    data: {
      wordpressPage: {
        acf: {
          color_template,
          title,
          background_image: {
            localFile: {
              childImageSharp: { fluid: coverImage },
            },
          },
          faq_section: {
            section_title,
            search_placeholder,
            search_button,
            accordion_faqs: faqsList,
          },
          first_main_image: {
            localFile: {
              childImageSharp: { fluid: secondCoverImage },
            },
          },
        },
      },
      contactForm,
    },
    pathContext,
  } = props;
  
  return (
    <div>
      <CoverImageTitle fluid={coverImage} title={title} />
      <FaqSerction
        sectionTitle={section_title}
        sectionPlaceholder={search_placeholder}
        searchButton={search_button}
        faqsList={faqsList} 
      />
      <CoverImage fluid={secondCoverImage} />
      <ContactForm
        color_template={color_template}
        contactForm={contactForm}
        lang={pathContext.lang}
        fluid={secondCoverImage}
      />
    </div>
  );
};
export const query = graphql`
  query($lang: String) {
    wordpressPage(slug: { eq: "faq" }, wpml_current_locale: { eq: $lang }) {
      title
      path
      yoast_head
      acf {
        color_template
        title
        background_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
                originalImg
              }
            }
          }
        }
        faq_section {
          section_title
          search_placeholder
          search_button
          accordion_faqs {
            button_label
            title
            button_label_close
            description
            image {
              localFile {
                childImageSharp {
                  fluid(maxHeight: 800, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
        first_main_image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    wordpressAcfOptions {
      options {
        kontakt {
          de_label
          en_label
        }
        produkte {
          de_label
          en_label
        }
        referenzen {
          de_label
          en_label
        }
        sprache {
          de_label
          en_label
        }
        consent_checkbox_label
        consent_hint
        dropdown_label
        dropdown_placeholder
        # title

        time_window_dropdown_options {
          title
        }
        contact_icons_footer {
          email
          phone
        }
        bottom_footer_left {
          name
          english_name
          url
        }
        bottom_footer_right {
          name
          english_name
          url
        }
        copyright_text
        send_success_message
        dropdown_validation_error_message
      }
    }
    contactForm: wordpressPage(
      slug: { eq: "contact-form-options" }
      wpml_current_locale: { eq: $lang }
    ) {
      acf {
        color_template
        contact_form_title
        description
        dropdown_label
        dropdown_placeholder
        consent_hint
        consent_checkbox_label
        send_success_message
        dropdown_validation_error_message
        time_window_dropdown_options {
          title
        }
        message_placeholder
        send_button
      }
    }
  }
`;
export default FAQTemplate;
